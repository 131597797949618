var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"ml-4",staticStyle:{"color":"#e33354"},attrs:{"cols":"12","sm":"12","md":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.cardTitle))])]),_c('v-col',{staticClass:"mt-1 text--secondary subtitle-2",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('div',[_c('span',[_vm._v("CSV exportálás")])]),_c('date-range-picker',{attrs:{"date-format":_vm.dateFormat,"locale-data":_vm.localeData,"opens":"right","ranges":false},on:{"update":_vm.exportOrder},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[(_vm.statuses.webshop && _vm.statuses.webshop.order)?_c('v-select',{attrs:{"items":_vm.statuses.webshop.order,"label":"Status","item-text":"$t('ORDER_STATUSES.'+value)","item-value":"key"},scopedSlots:_vm._u([{key:"selection",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm.$t("ORDER_STATUSES." + slotProps.item.key))+" ")]}},{key:"item",fn:function(slotProps){return [_c('span',{staticClass:"navi-text"},[_vm._v(_vm._s(_vm.$t("ORDER_STATUSES." + slotProps.item.key)))])]}}],null,false,4213357505),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('FORMS.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[(_vm.modalData)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredOrderCollection,"footer-props":{
          'items-per-page-options': [50, 75, 100],
        },"items-per-page":50,"search":_vm.search,"sort-by":['id'],"sort-desc":"true","loading":_vm.loadingTable},on:{"click:row":_vm.handleClickItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('OrderModalForm',{attrs:{"modalData":_vm.modalData,"permissions":_vm.permissions,"statuses":_vm.statuses,"endPoint":_vm.endPoint,"orderCollection":_vm.orderCollection},on:{"closeModalForm":_vm.handleCloseModalForm,"saveModalForm":_vm.handleSaveModalForm,"handleGLStranfer":_vm.handleGLStranfer,"new":_vm.handleNew}}),_c('v-spacer'),_c('DeleteModalDialog',{attrs:{"dialogDelete":_vm.dialogDelete},on:{"closeDelete":_vm.handleCloseDelete,"deleteItemConfirm":_vm.handleDeleteItemConfirm}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.permissionCan('delete'))?_c('v-icon',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e(),(
              item.status == 5 &&
              item.custom_fields &&
              item.custom_fields.shipping_type === 'delivery'
            )?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"secondary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleGLStranfer(item)}}},[_vm._v(" mdi-truck-fast ")]):_vm._e()]}}],null,false,3861841027)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }